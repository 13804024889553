<template>
  <div class="comparison">
    <div class="hide-print" v-if="!loading">
      <div class="is-flex overflow-x-auto mb-3" v-for="(item,key) in parameters" :key="key">
        <b-autocomplete
            open-on-focus
            append-to-body
            keep-first
            :data="options"
            placeholder="Parameter"
            field="label"
            class="mr-2 is-flex-grow-1"
            v-model="item.parameter"
            @typing="searchOptions"
            style="min-width: 100px"
            @select="option => handleSelectParameter(option, item)"/>

        <b-autocomplete
            v-if="item.selected_parameter && item.selected_parameter.type === 1"
            append-to-body
            open-on-focus
            keep-first
            select-on-click-outside
            :data="filteredProduct"
            :placeholder="$t('search')"
            field="product_name"
            v-model="item.product_name"
            @typing="searchProduct"
            clearable
            class="mr-2"
            @select="option => handleSelectProduct(option, item)"/>

        <b-autocomplete
            v-if="item.selected_parameter && item.selected_parameter.type === 9"
            append-to-body
            open-on-focus
            keep-first
            select-on-click-outside
            :data="filteredProbiotics"
            :placeholder="$t('search')"
            field="product_name"
            v-model="item.product_name"
            @typing="searchProbiotics"
            clearable
            class="mr-2"
            @select="option => handleSelectProbiotics(option, item)"/>

        <sg-select append-to-body :items="selectedFarm.blocks" type="is-secondary is-light"
                   :prepend="$t('farm.block')"
                   class="is-flex-grow-1 mr-2"
                   label="block_name"
                   v-model="item.block"
                   @change="getPond(item)"
                   v-if="!defaultCycle"
                   :placeholder="`${$t('select')} ${$t('farm.block')}`"/>

        <sg-select append-to-body :items="item.ponds" type="is-secondary is-light"
                   class="is-flex-grow-1 mr-2 ml-0"
                   label="name"
                   v-model="item.pond"
                   @change="getCycle(item)"
                   v-if="!defaultCycle"
                   :placeholder="`${$t('select')} ${$t('farm.pond')}`"/>

        <sg-select v-model="item.cycle" type="is-secondary is-light" no-outline
                   append-to-body :items="item.cycles"
                   label="cycle_name"
                   v-if="!defaultCycle"
                   :placeholder="$t('select') + ' ' + $t('cycle.cycle')" class="is-flex-grow-1 mr-2 ml-0"/>

        <b-button type="is-danger is-light" @click="removeParameter(key)" icon-left="trash-can-outline"></b-button>
      </div>

      <div class="is-flex is-justify-content-space-between mb-3">
        <b-button type="is-primary"
                  class="is-gradient"
                  icon-left="plus"
                  @click="addParameter()">{{ $t('add') }} Parameter
        </b-button>
        <div>
          <b-button type="is-info is-light" class="has-shadow" @click="saveTemplate" v-if="!defaultCycle">Save
            Template
          </b-button>
          <b-button type="is-info is-light" class="has-shadow ml-2" @click="getData">{{
              $t('comparison.compare')
            }}
          </b-button>
        </div>
      </div>
    </div>
    <div class="card" v-if="!chart.loading && chart.loaded && graph_data.length">
      <div class="card-content">
        <apexchart type="area" height="500" :options="chartOptions" :series="chart.series"></apexchart>
      </div>
    </div>
    <div v-if="!chart.loading && graph_data.length === 0 && chart.loaded" class="py-5 has-text-centered">
      {{ $t('no') }} Data
    </div>
  </div>
</template>

<script>
import SgSelect from "@/components/Sg/SgSelect";
import VueApexCharts from "vue-apexcharts";
import {mapActions, mapGetters} from "vuex";
import i18n from "@/i18n";

const ZScore = require('z-score').default

export default {
  name: "ComparisonChart",
  components: {
    SgSelect,
    apexchart: VueApexCharts,
  },
  props: {
    diseaseData: Array,
    deathData: Array,
    selectedCycle: Object,
    selectedPond: Object,
    defaultParameters: Array,
  },
  data: () => {
    return {
      loading: false,

      chart: {
        loaded: false,
        loading: false,
        series: [],
      },
      graph_data: [],
      parameters: [],
      options: [],

      feed_products: [],
      filteredProduct: [],

      probiotics_products: [],
      filteredProbiotics: [],
    }
  },
  watch: {
    selectedCycle: {
      deep: true,
      handler() {
        this.init()
      }
    }
  },
  created() {
    this.init()
  },
  computed: {
    selectedFarm() {
      return this.$store.state.farm.selectedFarm
    },

    ...mapGetters('comparison', [
      'typeOptions',
      'template'
    ]),

    defaultCycle() {
      return this.selectedPond && this.selectedCycle
    },

    annotations() {
      if ((this.diseaseData && this.diseaseData.length)  || (this.deathData && this.deathData.length) ) {
        let annotations = []

        let stock = this.selectedPond.stock.find(e => e.cycle_id === this.selectedCycle.cycle_id)

        if (this.diseaseData)
          for (let i = 0; i < this.diseaseData.length; i++) {
            let disease = this.diseaseData[i]
            annotations.push({
              x: this.$getDOC(stock.stock_date, disease.created_at),
              strokeDashArray: 0,
              borderColor: '#FF4060',
              label: {
                offsetY: -180,
                borderColor: '#FF4060',
                style: {
                  color: '#fff',
                  background: '#FF4060',
                },
                text: disease.disease_name,
              }
            })
          }
        if (this.deathData)
          for (let i = 0; i < this.deathData.length; i++) {
            let death = this.deathData[i]
            annotations.push({
              x: this.$getDOC(stock.stock_date, death.date),
              strokeDashArray: 0,
              borderColor: '#FF4060',
              label: {
                offsetY: -150,
                borderColor: '#FF4060',
                style: {
                  color: '#fff',
                  background: '#FF4060',
                },
                text: this.$t('death.shrimp') + ': ' + death.data.amount,
              }
            })
          }
        return {
          xaxis: annotations,
        }
      }
      return null
    },

    chartOptions() {
      let options = {
        chart: {
          animations: {
            enabled: false,
          },
          type: 'area',
          height: 500,
          zoom: {
            enabled: true
          },
          stacked: false,
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type: 'numeric',
          labels: {
            rotateAlways: false,
            formatter: (val) => {
              return 'DOC ' + parseInt(val)
            }
          }
        },
        yaxis: {
          show: false,
        },
        legend: {
          show: true
        },
        tooltip: {
          custom: function (opts) {
            const value = opts.ctx.w.config.series[opts.seriesIndex].data[opts.dataPointIndex]
            const label = opts.w.globals.seriesNames[opts.seriesIndex]
            return '<div class="arrow_box">' +
                '<p>' + `<span class="arrow_box_title">${label}</span>: <span class="arrow_box_value">${value.real_value}</span>` + '</p>' +
                '</div>'
          }
        },
      }
      if (this.annotations) {
        options.annotations = this.annotations
        options.grid =  {
          show: true,
          padding:{
            top: 180,
          },
        }
      }
      return options
    },
  },
  methods: {
    ...mapActions('comparison', [
      'addTemplate',
      'removeTemplate',
      'compare',
    ]),

    ...mapActions('farm', [
      'getWarehouse',
    ]),

    init() {
      this.parameters = []
      this.searchOptions()
      if (this.defaultParameters) {
        for (let i = 0; i < this.defaultParameters.length; i++) {
          let parameter = this.typeOptions.find(e => e.label.trim().toLowerCase().includes(this.defaultParameters[i].trim().toLowerCase()))
          if (parameter) {
            this.addParameter({
              pond: this.selectedPond,
              cycle: this.selectedCycle,
              selected_parameter: parameter,
              parameter: parameter.label,
            })
          }
        }
        this.getData()
      } else {
        this.addParameter()
        this.addParameter()
      }
    },

    handleSelectParameter(option, item) {
      item.selected_parameter = option

      let findFeed = this.parameters.find(e => e.selected_parameter && e.selected_parameter.type === 1)
      if (findFeed) this.getFeed()
      let findProbiotics = this.parameters.find(e => e.selected_parameter && e.selected_parameter.type === 9)
      if (findProbiotics) this.getProbiotics()
    },

    addParameter(item = null) {
      if (item) this.parameters.push(item)
      else {
        if (this.defaultCycle) {
          this.parameters.push({
            pond: this.selectedPond || null,
            cycle: this.selectedCycle || null,
            selected_parameter: null,
            parameter: null,
          })
        } else {
          this.parameters.push({
            block: null,
            pond: this.selectedPond || null,
            ponds: [],
            cycle: this.selectedCycle || null,
            cycles: [],
            selected_parameter: null,
            parameter: null,
          })
        }
      }
    },

    removeParameter(key) {
      this.parameters.splice(key, 1)
    },

    searchOptions(value = '') {
      this.options = this.typeOptions.filter(e => e.label.trim().toLowerCase().includes(value.trim().toLowerCase()))
    },
    saveTemplate() {
      let data = this.parameters.filter(e => {
        let filter = true

        for (let k in e) if (!e[k]) filter = false

        return filter
      }).map(e => {
        delete e.cycles
        delete e.ponds
        return e
      })

      if (data && data.length) {
        this.addTemplate(data)
      }
    },

    getData() {
      this.data = []
      this.$loading()

      this.chart.loading = true
      this.chart.loaded = false
      this.chart.series = []

      Promise.all(this._.cloneDeep(this.parameters).filter(e => {
        let filter = true

        for (let k in e) if (!e[k]) filter = false

        return filter
      }).map(async e => {
        let payload = {
          farm_id: this.farm_id,
          pond_id_1: e.pond.pond_id,
          cycle_id_1: e.cycle.cycle_id,
          type_1: e.selected_parameter.type,
        }

        if (e.product) payload.warehouse_id_1 = e.product.warehouse_id

        return await this.compare(payload)
      })).then(res => {
        res = res.filter(e => e && e.data && e.data.data1).map(e => e.data.data1)
        this.generateGraphData(res)

        this.$loading(false)
      })
    },

    loadTemplate(item) {
      this.$loading()
      this.parameters = []
      this.resetChart()
      Promise.all(this._.cloneDeep(item).map(async e => {
        await this.getPond(e, e.pond)
        await this.getCycle(e, e.cycle)
        return e
      })).then(res => {
        this.parameters = this._.cloneDeep(res)
      }).finally(() => {
        this.$loading(false)
      })
    },

    getPond(item, value = null) {
      item.ponds = []
      item.pond = value
      if (item.block) item.ponds = this.selectedFarm.ponds.filter(e => e.block_id === item.block.block_id)
    },

    async getCycle(item, value = null) {
      item.cycles = []
      item.cycle = value
      if (item.pond) {
        this.$store.dispatch('farm/getCycleOverview', {pond: item.pond}).then(res => {
          item.cycles = res
        })
      }
    },

    generateGraphData(data) {
      this.graph_data = []

      for (let i = 0; i < data.length; i++) {
        let item = data[i]

        if (item && item.length) {
          let parameter = this.parameters[i]

          let graph_data = {
            data: item,
            parameter: parameter,
            type: parameter.selected_parameter.type,
            field: parameter.selected_parameter.field,
            label: parameter.selected_parameter.label,
            series: null,
            options: null,
            block: parameter.block,
            pond: parameter.pond,
            cycle: parameter.cycle,
          }

          if (parameter.type === 7) {
            graph_data.totalAnco = graph_data.data.reduce((a, b) => b.data_anco.length > a ? b.data_anco.length : a, 0)
            graph_data.data.map(e => {
              e.data_anco.map(f => {
                switch (f.sisa) {
                  case 'Sisa Banyak':
                    f.value = 0
                    break
                  case 'Sisa Sedikit':
                    f.value = 1
                    break
                  case 'Habis':
                    f.value = 2
                    break
                }
                return f
              })
              return e
            })
          }

          this.graph_data.push(graph_data)
        }
      }

      this.loadGraphData()
    },

    async loadGraphData() {
      this.$loading()

      this.graph = []

      let series = []

      let has_different_fields = false

      let field = this.graph_data.filter(e => e).reduce((a, b) => {
        if (b) {
          let find = a.find(e => e === b.field)
          if (!find) a.push(b.field)
        }
        return a
      }, [])

      if (field.length > 1) has_different_fields = true

      // CHECKING IF FIRST AND SECOND COMPARISON TYPE HAS DIFFERENT TYPE
      // USING ZSCORE TO REBALANCE THE VALUE ON THE GRAPH - TOOLTIPS STILL SHOWING THE REAL VALUE

      if (has_different_fields) {
        let data = {}

        let min_value = 0

        let max = {}
        let min = {}

        for (let i = 0; i < this.graph_data.length; i++) {
          let graph_data = this.graph_data[i]
          if (graph_data) {
            if (graph_data.type !== 7) {
              data[graph_data.field] = []
              for (let j = 0; j < graph_data.data.length; j++) {
                let value = graph_data.data[j][graph_data.field]
                if (value && value !== -99) data[graph_data.field].push(value)
              }

              max[graph_data.field] = Math.max(...data[graph_data.field])
              min[graph_data.field] = Math.min(...data[graph_data.field])
            } else {
              data[graph_data.field] = []
              for (let j = 0; j < graph_data.data.length; j++) {
                graph_data.data[j].data_anco.map(e => {
                  if (e.value) data[graph_data.field].push(e.value)
                  return e
                })
              }

              max[graph_data.field] = Math.max(...data[graph_data.field])
              min[graph_data.field] = Math.min(...data[graph_data.field])
            }
          }
        }

        for (let k in max) {
          if (max[k] === min[k]) min[k] -= 1
        }

        data = [max, min]

        let zScore = new ZScore()
        zScore.train(data)
        // CONVERTING API DATA TO APEXCHART SERIES FORMAT

        for (let i = 0; i < this.graph_data.length; i++) {
          let graph_data = this.graph_data[i]
          if (graph_data) {
            graph_data.result = []
            if (graph_data.type === 2) {

              if (graph_data.field === 'ph') {
              let date = graph_data.data.filter(e => e[graph_data.field] !== -99).reduce((a,b) => {
                let date = this.$timestamp(this.$startDay(b.created_at))
                if (!a.includes(date)) a.push(date)
                return a
              }, [])

              graph_data.result.push(date.map(e=> {
                let morning_ph = 0
                let evening_ph = 0
                let ph_range = 0
                let find_morning = graph_data.data.find(f => f[graph_data.field] !== -99 && e === this.$timestamp(this.$startDay(f.created_at)) &&  parseInt(this.$timeFormat(f.created_at, 'HH')) < 12)
                let find_evening = graph_data.data.find(f => f[graph_data.field] !== -99 && e === this.$timestamp(this.$startDay(f.created_at)) &&  parseInt(this.$timeFormat(f.created_at, 'HH')) >= 12)

                if (find_morning) morning_ph = find_morning.ph
                if (find_evening) evening_ph = find_evening.ph

                if (morning_ph && evening_ph) ph_range = this.$number(evening_ph - morning_ph)

                return {
                  x: e,
                  y: ph_range,
                  real_value: ph_range,
                }
              }))
              } else {
                graph_data.result.push(graph_data.data.filter(e => e[graph_data.field] !== -99 && parseInt(this.$timeFormat(e.created_at, 'HH')) < 12).map((e) => {
                  let x = {}
                  x[graph_data.field] = e[graph_data.field]
                  let value = zScore.calculate(x)[graph_data.field]

                  if (min_value > value && e[graph_data.field] >= 0) min_value = value

                  return {
                    x: e.created_at,
                    y: value,
                    real_value: e[graph_data.field],
                  }
                }))

                graph_data.result.push(graph_data.data.filter(e => e[graph_data.field] !== -99 && parseInt(this.$timeFormat(e.created_at, 'HH')) >= 12).map((e) => {
                  let x = {}
                  x[graph_data.field] = e[graph_data.field]
                  let value = zScore.calculate(x)[graph_data.field]

                  if (min_value > value && e[graph_data.field] >= 0) min_value = value

                  return {
                    x: e.created_at,
                    y: value,
                    real_value: e[graph_data.field],
                  }
                }))
              }
            } else if (graph_data.type === 7) {
              for (let j = 0; j < graph_data.totalAnco; j++) {
                graph_data.result.push(graph_data.data.map((e) => {
                  let x = {}
                  x[graph_data.field] = e.data_anco[j].value
                  let value = zScore.calculate(x)[graph_data.field]

                  if (min_value > value && e.data_anco[j].value >= 0) min_value = value

                  return {
                    x: e.created_at,
                    y: value,
                    real_value: e.data_anco[j].sisa,
                  }
                }))
              }
            } else {
              graph_data.result.push(graph_data.data.filter(e => e[graph_data.field] !== -99).map((e) => {
                let x = {}
                x[graph_data.field] = e[graph_data.field]
                let value = zScore.calculate(x)[graph_data.field]

                if (min_value > value && e[graph_data.field] >= 0) min_value = value
                return {
                  x: graph_data.type === 8 ? e.date : e.created_at,
                  y: value,
                  real_value: graph_data.type === 4 ? this.$exponential(e[graph_data.field]) : this.$number(e[graph_data.field]),
                }
              }))
            }

            // MIN VALUE -> TO MAKE SURE NO REAL VALUES BELOW 0

            if (min_value < 0) {
              min_value *= -1
              graph_data.result = graph_data.result.map(e => {
                return e.map(f => {
                  f.y = f.y + min_value
                  return f
                })
              })
            }
          }
        }

      } else {

        // IF COMPARISON HAS THE SAME TYPE
        // CONVERTING API DATA TO APEXCHART SERIES FORMAT

        for (let i = 0; i < this.graph_data.length; i++) {
          let graph_data = this.graph_data[i]

          if (graph_data) {
            graph_data.result = []
            if (graph_data.type === 2) {
              graph_data.result.push(graph_data.data.filter(e => e[graph_data.field] !== -99 && parseInt(this.$timeFormat(e.created_at, 'HH')) < 12).map((e) => {
                return {
                  x: e.created_at,
                  y: e[graph_data.field],
                  real_value: e[graph_data.field],
                }
              }))
              graph_data.result.push(graph_data.data.filter(e => e[graph_data.field] !== -99 && parseInt(this.$timeFormat(e.created_at, 'HH')) >= 12).map((e) => {
                return {
                  x: e.created_at,
                  y: e[graph_data.field],
                  real_value: e[graph_data.field],
                }
              }))
            } else if (graph_data.type === 7) {
              for (let j = 0; j < graph_data.totalAnco; j++) {
                graph_data.result.push(graph_data.data.map((e) => {
                  return {
                    x: e.created_at,
                    y: e.data_anco[j].value,
                    real_value: e.data_anco[j].sisa,
                  }
                }))
              }
            } else {
              graph_data.result.push(graph_data.data.filter(e => e[graph_data.field] !== -99).map((e) => {
                return {
                  x: graph_data.type === 8 ? e.date : e.created_at,
                  y: e[graph_data.field],
                  real_value: graph_data.type === 4 ? this.$exponential(e[graph_data.field]) : this.$number(e[graph_data.field]),
                }
              }))
            }
          }
        }
      }

      for (let index = 0; index < this.graph_data.length; index++) {
        let graph_data = this.graph_data[index]
        if (graph_data) {
          let parameter = graph_data.parameter
          let type = parameter.selected_parameter.type

          // GET STOCK TO GET DOC

          let stock = await this.$store.dispatch('farm/getStockOverview', {
            pond_id: parameter.pond.pond_id,
          })

          stock = stock.find(e => e.cycle_id === parameter.cycle.cycle_id)

          if (stock) {

            // CONVERTING RESULT DATA TO APEXCHART FORMAT

            for (let i = 0; i < graph_data.result.length; i++) {
              let result = graph_data.result[i]

              let custom_append = ''

              if (type === 2) {
                if (parameter.parameter === 'pH') custom_append =' Range'
                else custom_append = ' - ' + (i % 2 === 0 ? 'Morning' : 'Evening')
              }

              series.push({
                name: graph_data.pond.name + ' ' + (graph_data.cycle.cycle_name || '') + ' - ' + graph_data.label + custom_append,
                data: result.sort((a, b) => a.x > b.x ? 1 : -1).map(e => {
                  return {
                    x: this.$getDOC(stock.stock_date, e.x),
                    y: e.y,
                    real_value: e.real_value,
                  }
                })
              })
            }
          }
        }
      }

      this.chart.series = series
      this.chart.loading = false
      this.chart.loaded = true

      this.$loading(false)
    },

    resetChart() {
      this.chart.loaded = false
      this.chart.series = []
    },

    getFeed() {
      if (this.feed_products.length === 0) {
        this.$loading()
        this.getWarehouse({
          farm_id: this.selectedFarm.farm_id,
          category_id: 5,
        }).then(res => {
          if (res && res.data && res.data.length) this.feed_products = res.data
        }).finally(() => {
          this.$loading(false)
        })
      }
    },
    getProbiotics() {
      if (this.probiotics_products.length === 0) {
        this.$loading()
        Promise.all([
          this.getWarehouse({
            farm_id: this.selectedFarm.farm_id,
            category_id: 3,
          }),
          this.getWarehouse({
            farm_id: this.selectedFarm.farm_id,
            category_id: 6,
          })
        ]).then(res => {
          for (let i = 0; i < res.length; i++) {
            if (res[i].data && res[i].data.length) {
              this.probiotics_products = this.probiotics_products.concat(res[i].data)
            }
          }
        }).finally(() => {
          this.$loading(false)
        })
      }
    },
    searchProduct(value = '') {
      let search = value.trim().toLowerCase()

      if (search) {
        this.filteredProduct = this.feed_products.filter(e => e.product_name.trim().toLowerCase().includes(search)).sort((a, b) => a.product_name.localeCompare(b.product_name, undefined, {
          numeric: true,
          sensitivity: 'base',
        }))
      } else this.filteredProduct = this._.cloneDeep(this.feed_products)
    },
    searchProbiotics(value = '') {
      let search = value.trim().toLowerCase()

      if (search) {
        this.filteredProbiotics = this.probiotics_products.filter(e => e.product_name.trim().toLowerCase().includes(search)).sort((a, b) => a.product_name.localeCompare(b.product_name, undefined, {
          numeric: true,
          sensitivity: 'base',
        }))
      } else this.filteredProbiotics = this._.cloneDeep(this.probiotics_products)
    },
    handleSelectProduct(option, item) {
      item.product = option
      if (item.product) {
        item.product_name = item.product.product_name
      }
      this.filteredProduct = []
    },
    handleSelectProbiotics(option, item) {
      item.product = option
      if (item.product) {
        item.product_name = item.product.product_name
      }
      this.filteredProbiotics = []
    },
  }
}
</script>


<style lang="scss">

.comparison {
  .arrow_box {
    position: relative;
    padding: 4px 12px;

    p {
      margin-bottom: 0;
    }
  }

  .apexcharts-tooltip {
    transform: translateX(10px) translateY(10px);
    overflow: visible !important;
    white-space: normal !important;
  }

  .apexcharts-tooltip span {
    display: inline-block;
  }

  .arrow_box_title {
    font-size: .9rem;
  }

  .arrow_box_value {
    font-weight: bold;
  }
}

</style>
